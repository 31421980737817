import React, { useEffect, useState, useCallback } from 'react';
import './OpcoesAdmin.css';
import Navbar from '../../Components/Navbar/navbar';
import { useHistory, useParams } from 'react-router-dom';
import firebase from '../../Config/firebase.js';

function OpcoesAdmin() {
  const history = useHistory();
  const { aba } = useParams();
  const [candidatos, setCandidatos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [abaAtiva, setAbaAtiva] = useState(aba || 'candidatos');

  const db = firebase.firestore();

  function handleVoltarClick() {
    history.push('/app/home');
  }

  const getCandidatos = useCallback(async () => {
    try {
      const candidatosRef = db.collection('candidatos');
      const snapshot = await candidatosRef.get();
      const candidatosData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setCandidatos(candidatosData);
    } catch (error) {
      console.error('Erro ao buscar os dados dos candidatos:', error);
    }
  }, [db]);

  const getUsuarios = useCallback(async () => {
    try {
      const usuariosRef = db.collection('usuarios');
      const snapshot = await usuariosRef.get();
      const usuariosData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setUsuarios(usuariosData);
    } catch (error) {
      console.error('Erro ao buscar os dados dos Usuarios:', error);
    }
  }, [db]);

  async function handleExcluirCandidato(id) {
    try {
      const candidatoRef = db.collection('candidatos').doc(id);
      await candidatoRef.delete();
      getCandidatos();
    } catch (error) {
      console.error('Erro ao excluir o candidato:', error);
    }
  }

  async function concederPermissaoUsuario(id, novaPermissao) {
    try {
      const usuarioRef = db.collection('usuarios').doc(id);
      await usuarioRef.update({ permissao: novaPermissao });

      setUsuarios((prevState) =>
        prevState.map((usuario) =>
          usuario.id === id ? { ...usuario, permissao: novaPermissao } : usuario
        )
      );
    } catch (error) {
      console.error('Erro ao conceder permissão ao usuário:', error);
    }
  }

  useEffect(() => {
    getCandidatos();
    getUsuarios();
  }, [getCandidatos, getUsuarios]);

  useEffect(() => {
    if (aba === 'candidatos' || aba === 'usuarios') {
      setAbaAtiva(aba);
    }
  }, [aba]);

  function formatGitHubLink(link) {
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      return 'http://' + link;
    }
    return link;
  }

  return (
    <div className="opcoes-admin">
      <Navbar />
      <h1>Opções de Admin</h1>
      <ul className="nav nav-tabs aba">
        <li className="nav-item">
          <a
            className={`nav-link ${abaAtiva === 'candidatos' ? 'active' : ''}`}
            href
            onClick={(e) => {
              e.preventDefault();
              setAbaAtiva('candidatos');
              history.push(`/app/opcoesadmin`);
            }}
          >
            Dados dos Candidatos
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${abaAtiva === 'usuarios' ? 'active' : ''}`}
            href
            onClick={(e) => {
              e.preventDefault();
              setAbaAtiva('usuarios');
              history.push(`/app/opcoesadmin`);
            }}
          >
            Usuários Cadastrados
          </a>
        </li>
      </ul>
      {abaAtiva === 'candidatos' ? (
        <div>
          {candidatos.length === 0 ? (
            <p>Nenhum registro. À medida que os candidatos enviarem seus dados, eles serão apresentados aqui.</p>
          ) : (
            <table className="table table-admin">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Telefone</th>
                  <th>Link do Projeto</th>
                  <th>Data de Envio</th>
                  <th>Recrutador</th>
                  <th className="text-center">Excluir</th>
                </tr>
              </thead>
              <tbody>
                {candidatos.map((candidato) => (
                  <tr key={candidato.id}>
                    <td>{candidato.nome}</td>
                    <td>{candidato.email}</td>
                    <td>{candidato.telefone}</td>
                    <td>
                      <a href={formatGitHubLink(candidato.githubLink)} target="_blank" rel="noopener noreferrer">
                        Projeto - Candidato {candidato.nome}
                      </a>
                    </td>
                    <td>{candidato.dataEnvio ? candidato.dataEnvio.toDate().toLocaleString() : 'Data desconhecida'}</td>
                    <td>{candidato.recrutador}</td>
                    <td className="text-center">
                      <a
                        href
                        onClick={(e) => {
                          e.preventDefault();
                          handleExcluirCandidato(candidato.id);
                        }}
                        className="link-lixeira"
                      >
                        <i className="far fa-trash-alt icone-acao red" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <div>
          <table className="table table-admin">
            <thead>
              <tr>
                <th>E-mail</th>
                <th>Permissão de Admin</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((usuario) => (
                <tr key={usuario.id}>
                  <td>{usuario.email}</td>
                  <td>
                    <select
                      className="form-select"
                      value={usuario.permissao}
                      onChange={(e) =>
                        concederPermissaoUsuario(usuario.id, e.target.value)
                      }
                    >
                      <option value="">Selecionar</option>
                      <option value="admin">Permissão Concedida</option>
                      <option value="remove">Permissão Negada</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <button onClick={handleVoltarClick} className="btn-voltar">
        <i className="fas fa-chevron-left"></i> Voltar
      </button>
    </div>
  );
}

export default OpcoesAdmin;
